export default {
  Address: {
    minLength: 2,
    maxLength: 40
  },
  ZipCode: {
    minLength: 5
  },
  City: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  CountryCode: {
    required: true,
    minLength: 2
  },
}