<orders-feature>
  <h2 class="header">Ordrar</h2>
  <pagination-view onchange={this.onChangePage} current={opts.currentPage} take={opts.take} count={opts.orderdata ?
    opts.orderdata.TotalCount : 0} />
  <table class="default responsive subrows">
    <thead>
      <tr>
        <th>Ordernummer</th>
        <th>Datum</th>
        <th>Status</th>
        <th>Leverassätt</th>
        <th class="right">Summa</th>
        <th class="right">Moms</th>
        <th class="right">Totalt</th>
        <th class="button"></th>
      </tr>
    </thead>
    <tbody if={orders.length} each={order in orders}>
      <tr>
        <td data-header="Ordernummer">{order.Id}</td>
        <td data-header="Datum">{this.getCreateDate(order)}</td>
        <td data-header="Status">{this.getOrderStatus(order)}</td>
        <td data-header="Leverassätt">{order.WayOfDeliveryName}</td>
        <td data-header="Summa" class="right">{order.AmountExludeVat.format(2)}</td>
        <td data-header="Moms" class="right">{order.VatAmount.format(2)}</td>
        <td data-header="Totalt" class="right">{order.TotalAmountIncludeVat.format(2)}</td>
        <td><button type="button" class="defaultButton tiny" onclick={this.onShowRows}>Visa rader</button></td>
      </tr>
      <tr class="subtable" if={this.opts.viewOrderRows.indexOf(order.Id) !==-1}>
        <td colSpan="20">
          <table>
            <thead>
              <tr>
                <th class="left articleNumber">ArtNr</th>
                <th class="left">Artikeltext</th>
                <th class="right">Pris</th>
                <th class="right">Antal</th>
                <th class="right">Totalt</th>
              </tr>
            </thead>
            <tbody>
              <tr each={row in this.getOrderRows(order.Id)} key={row.Id}>
                <td data-header="ArtNr" class="left">{row.ArticleNumber}</td>
                <td data-header="Artikeltext" class="left">{row.Description}</td>
                <td data-header="Pris" class="right">{row.Price.format(2)}</td>
                <td data-header="Antal" class="right">{row.Quantity}</td>
                <td data-header="Totalt" class="right">{row.TotalAmount.format(2)}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
    <tbody if={noRows}>
      <tr>
        <td colSpan="10" class="noRows">Inga rader hittades...</td>
      </tr>
    </tbody>
  </table>
  <loading-overlay if={opts.loading} />
  <pagination-view onchange={this.onChangePage} current={opts.currentPage} take={opts.take} count={opts.orderdata ?
    opts.orderdata.TotalCount : 0} />
  <script>
    import './style.scss'
    import {
      fetchOrders,
      fetchOrderRows
    } from './duck'
    import 'tags/common/pagination-view'
    import 'tags/common/loading-overlay'
    import moment from 'moment'
    import orderStatus from 'lib/enums/orderStatus'
    this.noRows = false
    this.orders = []

    const mapStateToOpts = state => {
      return {
        loadingRows: state.orders.loadingRows,
        loading: state.orders.loading,
        orderdata: state.orders.data,
        currentPage: state.orders.currentPage,
        take: state.orders.take,
        viewOrderRows: state.orders.viewOrderRows,
        orderRows: state.orders.orderRows
      }
    }
    this.reduxConnect(mapStateToOpts)

    this.getOrderRows = (orderId) => {
      const {
        orderRows
      } = this.opts

      const rows = orderRows && orderRows[orderId]
      return rows || []
    }

    this.getCreateDate = (order) => {
      return moment(order.CreateDate).format('YYYY-MM-DD')
    }

    this.onShowRows = (e) => {
      const order = e.item.order
      this.opts.dispatch(fetchOrderRows(order.Id))
    }

    this.isDelivered = (order) => {
      return order.Status === orderStatus.Delivered || order.Status === orderStatus.Invoiced
    }

    this.getOrderStatus = order => {
      const isDelivered = order.Status === orderStatus.Delivered || order.Status === orderStatus.Invoiced
      if (isDelivered) {
        return 'Skickad'
      } else {
        return 'Order'
      }
    }

    this.onChangePage = page => {
      this.opts.dispatch(fetchOrders(page))
    }

    this.on('update', () => {
      const {
        loading,
        orderdata
      } = this.opts

      this.noRows = orderdata && orderdata.Orders.length === 0 && !loading
      this.orders = orderdata ? orderdata.Orders : []
    })

    this.on('mount', () => {
      const {
        loading
      } = this.opts

      if (!loading) {
        this.opts.dispatch(fetchOrders(1))
      }
    })
  </script>
</orders-feature>