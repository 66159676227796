import {
  applyMiddleware,
  createStore,
  compose
} from 'redux'

import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import fetchMiddleware from './fetchMiddleware'
import unauthorizedMiddleware from './unauthorizedMiddleware'

const middleware = applyMiddleware(
  thunk,
  fetchMiddleware,
  unauthorizedMiddleware
)

let reduxDevTools = null
if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
  reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
}

const composeEnhancers = reduxDevTools || compose

const store = createStore(
  rootReducer,
  composeEnhancers(middleware)
)

export default store
