import initialState from './initialState'

const FETCH_IMAGES = 'iam/pages/aboutus-page/FETCH_IMAGES'
const FETCH_IMAGES_SUCCESS = 'iam/pages/aboutus-page/FETCH_IMAGES_SUCCESS'
const FETCH_IMAGES_FAILED = 'iam/pages/aboutus-page/FETCH_IMAGES_FAILED'

//Actions
export const fetchImages = () => {
  return {
    types: [FETCH_IMAGES, FETCH_IMAGES_SUCCESS, FETCH_IMAGES_FAILED],
    callApi: {
      endpoint: 'api/webimages/4',
      method: 'GET'
    }
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_IMAGES:
      return {
        ...state,
        loading: true
      }
    case FETCH_IMAGES_SUCCESS:
      return {
        ...state,
        images: action.data,
        loading: false
      }
    case FETCH_IMAGES_FAILED:
      return {
        ...state,
        images: null,
        loading: false
      }
    default:
      return state
  }
}