<categories-navigation>
  <h3 class="header">Produkter</h3>
  <div onclick={showHighlights} class="category">
    <span class="mainGroups {active: !opts.productGroupId}">Bästsäljare</span>
  </div>
  <div onclick={categoryNav} each={group in productgroups} class="category {hasChildren: group.SubGroups}">
    <span class="mainGroups {active: (group.ProductGroupId === opts.productGroupId && !group.HasChildren)}">{group.Name}</span>
    <div onclick={subGroupNav} class="subGroups" each={subGroup in group.SubGroups} if={group.SubGroups.length}>
      <span class="{active: subGroup.ProductGroupId === opts.productGroupId}">{subGroup.Name}</span>
    </div>
  </div>
  <script>
    import route from 'riot-route'
    import {
      urlName
    } from 'lib/utils'
    import './style.scss'
    this.productgroups = []
    this.localLogin = null
    const mapStateToOpts = state => {
      return {
        categories: state.products.groups.items,
        productGroupId: state.products.articles.productGroupId,
        isLoggedIn: state.login.loggedIn
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.showHighlights = () => {
      route('shop/highlighted/articles')
    }

    this.subGroupNav = (e) => {
      e.stopPropagation()
      const {
        Name,
        ProductGroupId
      } = e.item.subGroup
      const name = urlName(Name)
      route(`shop/${name}/${ProductGroupId}`)
    }

    this.categoryNav = (e) => {
      if (e.item && e.item.group.HasChildren)
        return
      else {
        const {
          Name,
          ProductGroupId
        } = e.item.group
        const name = urlName(Name)
        route(`shop/${name}/${ProductGroupId}`)
      }
    }

    this.processGroups = () => {
      let filteredGroups
      if (this.opts.categories) {
        if (!this.opts.isLoggedIn) {
          filteredGroups = this.opts.categories.filter(group => !group.RequireLogin)
        } else {
          filteredGroups = this.opts.categories
        }
      }

      let main
      if (filteredGroups)
        main = filteredGroups.filter(item => !item.ParentId)

      if (filteredGroups) {
        main.forEach(group => {
          const subGroups = filteredGroups.filter(item => item.ParentId === group.ProductGroupId)
          group.SubGroups = subGroups.length ? subGroups : null
        })

        this.productgroups = main
        this.localLogin = this.opts.isLoggedIn

        this.update()
      }
    }

    this.on('update', () => {
      if (this.productgroups.length <= 0 || this.localLogin !== this.opts.isLoggedIn)
        this.processGroups()
    })

    this.on('mount', () => {
      this.processGroups()
    })
  </script>
</categories-navigation>