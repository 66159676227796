import initialState from './initialState'

const UPDATE_ACCOUNT_INFO = 'iam/tags/pages/profile-page/UPDATE_ACCOUNT_INFO'
const UPDATE_ACCOUNT_INFO_SUCCESS = 'iam/tags/pages/profile-page/UPDATE_ACCOUNT_INFO_SUCCESS'
const UPDATE_ACCOUNT_INFO_FAILED = 'iam/tags/pages/profile-page/UPDATE_ACCOUNT_INFO_FAILED'
const SET_INITIAL_ACCOUNT_INFO = 'iam/features/shoppingcart/checkout-feature/SET_INITIAL_INVOICE_INFO'

const UPDATE_DELIVERY_INFO = 'iam/tags/pages/profile-page/UPDATE_DELIVERY_INFO'
const UPDATE_DELIVERY_INFO_SUCCESS = 'iam/tags/pages/profile-page/UPDATE_DELIVERY_INFO_SUCCESS'
const UPDATE_DELIVERY_INFO_FAILED = 'iam/tags/pages/profile-page/UPDATE_DELIVERY_INFO_FAILED'
const SET_DELIVERY_INFO = 'iam/features/shoppingcart/checkout-feature/SET_DELIVERY_INFO'

const ADD_DELIVERY_INFO = 'iam/tags/pages/profile-page/ADD_DELIVERY_INFO'
const ADD_DELIVERY_INFO_SUCCESS = 'iam/tags/pages/profile-page/ADD_DELIVERY_INFO_SUCCESS'
const ADD_DELIVERY_INFO_FAILED = 'iam/tags/pages/profile-page/ADD_DELIVERY_INFO_FAILED'

const ACCOUNT_FORM_CHANGED = 'iam/tags/pages/profile-page/ACCOUNT_FORM_CHANGED'
const DELIVERY_FORM_CHANGED = 'iam/tags/pages/profile-page/DELIVERY_FORM_CHANGED'
const ADD_DELIVERY_FORM_CHANGED = 'iam/tags/pages/profile-page/ADD_DELIVERY_FORM_CHANGED'

const FETCH_VISITING_ADDRESS = 'iam/tags/pages/profile-page/FETCH_VISITING_ADDRESS'
const FETCH_VISITING_ADDRESS_SUCCESS = 'iam/tags/pages/profile-page/FETCH_VISITING_ADDRESS_SUCCESS'
const FETCH_VISITING_ADDRESS_FAILED = 'iam/tags/pages/profile-page/FETCH_VISITING_ADDRESS_FAILED'

const SET_VISITING_ADDRESS = 'iam/tags/pages/profile-page/SET_VISITING_ADDRESS'
const UPDATE_VISITING_ADDRESS = 'iam/tags/pages/profile-page/UPDATE_VISITING_ADDRESS'
const UPDATE_VISITING_ADDRESS_SUCCESS = 'iam/tags/pages/profile-page/UPDATE_VISITING_ADDRESS_SUCCESS'
const UPDATE_VISITING_ADDRESS_FAILED = 'iam/tags/pages/profile-page/UPDATE_VISITING_ADDRESS_FAILED'

export const updateAccountInfo = (payload) => {
  return {
    types: [UPDATE_ACCOUNT_INFO, UPDATE_ACCOUNT_INFO_SUCCESS, UPDATE_ACCOUNT_INFO_FAILED],
    callApi: {
      endpoint: 'api/customer/',
      method: 'PUT',
      data: payload
    }
  }
}

export const updateDeliveryInfo = (id, payload) => {
  return {
    types: [UPDATE_DELIVERY_INFO, UPDATE_DELIVERY_INFO_SUCCESS, UPDATE_DELIVERY_INFO_FAILED],
    callApi: {
      endpoint: 'api/customeraddress/' + id,
      method: 'PUT',
      data: payload
    }
  }
}

export const fetchVisitingAddress = () => {
  return {
    types: [FETCH_VISITING_ADDRESS, FETCH_VISITING_ADDRESS_SUCCESS, FETCH_VISITING_ADDRESS_FAILED],
    callApi: {
      endpoint: 'api/customerVisitingAddress/',
      method: 'GET',
    }
  }
}

export const updateVisitingAddress = (payload) => {
  return {
    types: [UPDATE_VISITING_ADDRESS, UPDATE_VISITING_ADDRESS_SUCCESS, UPDATE_VISITING_ADDRESS_FAILED],
    callApi: {
      endpoint: 'api/customerVisitingAddress/',
      method: 'PUT',
      data: payload
    }
  }
}

export const setVisitingAddress = (name, value) => {
  return {
    type: SET_VISITING_ADDRESS,
    name,
    value
  }
}

export const setDeliveryInfo = (deliveryInfo) => {
  return {
    type: SET_DELIVERY_INFO,
    deliveryInfo
  }
}

export const addDeliveryInfo = (id, payload) => {
  return {
    types: [ADD_DELIVERY_INFO, ADD_DELIVERY_INFO_SUCCESS, ADD_DELIVERY_INFO_FAILED],
    callApi: {
      endpoint: 'api/customeraddress/' + id,
      method: 'POST',
      data: payload
    }
  }
}

export const setInitialAccountInfo = (accountInfo) => {
  return {
    type: SET_INITIAL_ACCOUNT_INFO,
    accountInfo
  }
}

export const accountFormChanged = (name, value) => {
  return {
    type: ACCOUNT_FORM_CHANGED,
    name,
    value
  }
}

export const deliveryFormChanged = (name, value) => {
  return {
    type: DELIVERY_FORM_CHANGED,
    name,
    value
  }
}

export const addDeliveryFormChanged = (name, value) => {
  return {
    type: ADD_DELIVERY_FORM_CHANGED,
    name,
    value
  }
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_ACCOUNT_INFO:
      return {
        ...state,
        loading: true,
        errorMessage: null
      }
    case UPDATE_ACCOUNT_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        accountForm: {
          ...state.accountForm,
          currentPassword: '',
          newPassword: '',
          confirmNewPassword: ''
        }
      }
    case UPDATE_ACCOUNT_INFO_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.message
      }
    case UPDATE_DELIVERY_INFO:
      return {
        ...state,
        loading: true
      }
    case UPDATE_DELIVERY_INFO_SUCCESS:
      return {
        ...state,
        loading: false
      }
    case UPDATE_DELIVERY_INFO_FAILED:
      return {
        ...state,
        loading: false
      }
    case ADD_DELIVERY_INFO:
      return {
        ...state,
        loading: true
      }
    case ADD_DELIVERY_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        addDeliveryForm: {
          name: '',
          address1: '',
          address2: '',
          zipcode: '',
          city: '',
          countryCode: ''
        }
      }
    case ADD_DELIVERY_INFO_FAILED:
      return {
        ...state,
        loading: false
      }
    case ACCOUNT_FORM_CHANGED:
      return {
        ...state,
        accountForm: {
          ...state.accountForm,
          [action.name]: action.value
        },
        errorMessage: null
      }
    case DELIVERY_FORM_CHANGED:
      return {
        ...state,
        deliveryForm: {
          ...state.deliveryForm,
          [action.name]: action.value
        },
        errorMessage: null
      }
    case ADD_DELIVERY_FORM_CHANGED:
      return {
        ...state,
        addDeliveryForm: {
          ...state.addDeliveryForm,
          [action.name]: action.value
        },
        errorMessage: null
      }
    case SET_INITIAL_ACCOUNT_INFO:
      return {
        ...state,
        accountForm: action.accountInfo
      }
    case SET_DELIVERY_INFO:
      return {
        ...state,
        deliveryForm: action.deliveryInfo
      }
    case SET_VISITING_ADDRESS:
      return {
        ...state,
        visitingAddress: {
          ...state.visitingAddress,
          [action.name]: action.value
        }
      }
    case FETCH_VISITING_ADDRESS_SUCCESS:
      return {
        ...state,
        visitingAddress: {
          ...action.data,
          CountryCode: action.data.CountryCode || 'SE'
        }
      }
    case UPDATE_VISITING_ADDRESS:
      return {
        ...state,
        loading: true
      }
    case UPDATE_VISITING_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}