<shoppingcart-item>
  <div class="cartItems col-sm-12 col-xs-12  row">
    <div class="col-sm-1 col-xs-1 middle-sm middle-xs row">
      <span onclick={deleteArticle} class="deleteButton">x</span>
    </div>
    <div class="col-sm-11 col-xs-11 middle-sm middle-xs row">
      <div class="imgDiv col-sm-1 col-xs-1 xs-hide">
        <img riot-src={apiUrl}{endpoint}/{cartItem.ImageId} alt="">
      </div>
      <div onclick={gotoArticle} class="text col-sm-7 col-xs-11">{cartItem.ArticleText}</div>
      <div class="quantityPP col-sm-2 col-xs-3 end-sm">
        <div class="xs-show center-xs">Antal</div>
        <div>
          <select onchange={onChangeQuantity} ref="quantity" name="quantity">
            <option each={quantity in addQuantities} selected={quantity===cartItem.Quantity} value={quantity}>{quantity}</option>
          </select>
        </div>
      </div>
      <div class="price col-sm-1 col-xs-4 center-xs">
        <div class="xs-show ">Pris</div>
        <div> {cartItem.Price.format(2)}kr</div>
      </div>
      <div class="total col-sm-1 end-sm col-xs-4 center-xs">
        <div class="xs-show">Totalt</div>
        <div>{(cartItem.Price * cartItem.Quantity).format(2)}kr</div>
      </div>
    </div>
  </div>
  <script>
    import './style.scss'
    import route from 'riot-route'
    import {
      urlName
    } from 'lib/utils'
    import {
      updateCartArticle,
      removeCartArticle
    } from 'tags/features/shoppingcart/duck'
    this.apiUrl = process.env.API_URL
    this.endpoint = 'api/articleimage/'
    this.cartItem = opts.cartitem
    this.addQuantities = null

    const mapStateToOpts = state => {
      return {
        shoppingcart: state.shoppingcart
      }
    }
    this.reduxConnect(mapStateToOpts)


    this.getQuantities = () => {
      if (!this.addQuantities && this.cartItem) {
        this.addQuantities = []

        for (let i = 1; i <= 4; i++) {
          this.addQuantities.push(this.cartItem.QuantityPerPackage * i)
        }
      }
      this.update()
    }

    this.onChangeQuantity = () => {
      const Id = this.cartItem.Id
      const quantity = this.refs.quantity.value

      if (quantity && quantity !== '')
        this.opts.dispatch(updateCartArticle(Id, quantity))
    }

    this.gotoArticle = (e) => {
      const articletext = urlName(`${e.item.row.ArticleText}`)

      if (e.item.row)
        route(`article/${articletext}/${e.item.row.ArticleId}`)
    }

    this.deleteArticle = (e) => {
      if (e.item.row.Id)
        this.opts.dispatch(removeCartArticle(e.item.row.Id))
    }

    this.on('update', () => { })

    this.on('mount', () => {
      this.getQuantities()
    })
  </script>
</shoppingcart-item>