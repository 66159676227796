Number.prototype.format = function (n, x, s, c) {
  const showZeros = n !== undefined
  n = (n === undefined ? 2 : n)
  x = (x === undefined ? 3 : x)
  s = (s === undefined ? ' ' : s)
  c = (c === undefined ? ',' : c)

  var re = '\\d(?=(\\d{' + x + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = this.toFixed(Math.max(0, ~~n))

  if (n > 0 && !showZeros) {
    const lastNumbers = Number(num.slice(-n))
    if (lastNumbers === 0)
      num = num.slice(0, -(n + 1))
  }

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + s)
}

if (!Array.prototype.contains) {
  Array.prototype.contains = function (value) {
    if (this === null) {
      throw new TypeError('Array.prototype.contains called on null or undefined')
    }

    return this.indexOf(value) >= 0
  }
}