<related-articles>
  <div if={!opts.loading && opts.items}>
    <h2>Relaterade Produkter</h2>
    <article-item each={article in opts.items} article={article} />
  </div>
  <loading-spinner if={opts.loading} />
  <script>
    import './style.scss'
    import 'tags/articlecards/article-item'
    import 'tags/common/loading-spinner'
    import {
      fetchRelatedProducts
    } from './duck'

    this.articleId = null

    const mapStateToProps = state => {
      return {
        loading: state.article.related.loading,
        items: state.article.related.items
      }
    }

    this.reduxConnect(mapStateToProps)

    this.fetchArticles = () => {
      if (this.articleId !== this.opts.articleid) {
        this.articleId = this.opts.articleid
        this.opts.dispatch(fetchRelatedProducts(this.articleId))
      }
    }

    this.on('update', () => {
      this.fetchArticles()
    })

    this.on('mount', () => {
      this.fetchArticles()
    })
  </script>
</related-articles>