import initialState from './initialState'

const FETCH_ARTICLE = 'iam/features/article/article-feature/FETCH_ARTICLE'
const FETCH_ARTICLE_SUCCESS = 'iam/features/article/article-feature/FETCH_ARTICLE_SUCCESS'
const FETCH_ARTICLE_FAILED = 'iam/features/article/article-feature/FETCH_ARTICLE_FAILED'

const ADDING_TO_CART = 'iam/features/article/article-feature/ADDING_TO_CART'

//from shoppingcart duck
import {
  ADD_TO_CART_SUCCESS
} from 'tags/features/shoppingcart/duck'


//Actions
export const fetchArticle = id => {
  return {
    types: [FETCH_ARTICLE, FETCH_ARTICLE_SUCCESS, FETCH_ARTICLE_FAILED],
    callApi: {
      endpoint: 'api/article/' + id,
      method: 'GET'
    }
  }
}

export const addingToCart = () => {
  return {
    type: ADDING_TO_CART
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICLE:
      return {
        ...state,
        loading: true,
        data: null,
        addedToCart: false,
        addingToCart: false 
      }
    case FETCH_ARTICLE_SUCCESS:
      return {
        ...state,
        data: action.data,
        loading: false
      }
    case FETCH_ARTICLE_FAILED:
      return {
        ...state,
        data: null,
        loading: false
      }
    case ADDING_TO_CART:
      return {
        ...state,
        addedToCart: false,
        addingToCart: true
      }
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        addedToCart: true,
        addingToCart: false
      }
    default:
      return state
  }
}