<instagram-feed>
  <div id="instafeed"></div>
  <script>
    import './style.scss'
    // import Instafeed from 'instafeed.js'
    this.created = false

    const mapStateToProps = state => {
      return {
        token: state.app.settings.InstagramToken
      }
    }

    this.reduxConnect(mapStateToProps)

    this.on('update', () => {
      if (this.opts.token && !this.created && typeof(Instafeed) !== 'undefined') {
        this.created = true

        const feed = new Instafeed({
          accessToken: this.opts.token,
          sortBy: 'most-recent',
          limit: 6,
          //resolution: 'thumbnail' // (default) - 150x150
          //resolution: 'low_resolution' // 306x306
        })
        feed.run()        
      }
    })

    //npm i stevenschobert/instafeed.js#release/2.0.0 --save
    this.on('mount', () => {
      this.update()
    })
  </script>
</instagram-feed>

