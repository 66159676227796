<resellers-feature>
  <div if={!opts.loading} class="row inputs">
    <div class="col-xs-12 col-sm-4">
      <default-select value="SE" value={selectedCountry} items={countries} label="Land" onchange={onCountryChange} />
    </div>
    <div class="col-xs-12 col-sm-4">
      <default-select value={selectedCity} items={cities} label="Stad" onchange={onCityChange} />
    </div>
  </div>
  <div if={resellers}>
    <div each={reseller in resellers} class="reseller">
      <h3>{reseller.Name}</h3>
      <div class="info" if={reseller.Address && reseller.Address.length && reseller.ZipCode && reseller.ZipCode.length}>
        <strong>Besöksadress</strong><br>
        {reseller.Address}<br>
        {reseller.ZipCode} {selectedCity}
      </div>
      <div class="info" if={reseller.Telephone}>
        <strong>Telefon</strong><br>
        {reseller.Telephone}
      </div>
      <div class="info" if={reseller.Website}>
        <a href={reseller.Website} target="_blank">{reseller.Website}</a>
      </div>
    </div>
  </div>
  <loading-spinner if={opts.loading} />

  <script>
    import './style.scss'
    import {
      fetchResellers
    } from './duck'
    import 'tags/common/loading-spinner'
    import 'tags/common/inputs/default-select'

    this.selectedCountry = 'SE'
    this.selectedCity = ''
    this.countries = null
    this.cities = null
    this.resellers = null

    const mapStateToOpts = state => {
      return {
        items: state.resellers.items,
        loading: state.resellers.loading
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.onCountryChange = e => {
      const value = e.target.value;
      this.selectedCountry = value;
      this.setCities(this.selectedCountry);
      this.update();
    }

    this.onCityChange = e => {
      const value = e.target.value;
      this.selectedCity = value;
      console.log(this.selectedCity);
      this.showCustomers(value);
      this.update();
    }

    this.setCountries = () => {
      if (this.opts.items) {
        this.countries = this.opts.items ? this.opts.items.map(i => ({
          value: i.CountryCode,
          text: i.CountryName
        })) : [];

        this.setCities(this.selectedCountry);
      }
    }

    this.setCities = countryCode => {
      this.cities = this.opts.items.find(i => i.CountryCode === countryCode).Cities.map(c => ({
        value: c.City,
        text: c.City
      }))

      this.selectedCity = this.cities[0].value;
      this.showCustomers(this.selectedCity)
    }

    this.showCustomers = city => {
      this.resellers = this.opts.items.find(i => i.CountryCode === this.selectedCountry).Cities.find(c => c.City === city).Customers;
    }

    this.on('update', () => {
      if(!this.opts.loading && !this.countries)
        this.setCountries();
    })

    this.on('mount', () => {
      if (!this.opts.items) {
        this.opts.dispatch(fetchResellers())
      } else {
        this.setCountries();
      }

      setTimeout(() => {
        this.update();
      }, 0)
    })
  </script>
</resellers-feature>