<imagesdl-page>
  <product-slider />
  <div class="content">
    <h3>Nedladdning Bilder</h3>
    <div>NU FINNS ALLA NYA MILJÖBILDER SAMT NYA PRODUKTER FÖR NEDLADDNING.</div>
    <div>
      <a href="https://www.dropbox.com/sh/3r2rqlv7si7pmkm/AAArdyeM5z-LPrsxleHlm_kha?dl">Här kan ni ladda ner våra produktbilder samt våra miljöbilder. Det är endast ni som kund som får använda dessa.</a>
    </div>
    <div>
      <a href="https://www.dropbox.com/sh/3r2rqlv7si7pmkm/AAArdyeM5z-LPrsxleHlm_kha?dl">Klicka här
        <a/a>
    </div>
  </div>
  <script>
    import './style.scss'
    import 'tags/features/sliders/product-slider'
  </script>
</imagesdl-page>