<account-form>
  <h2 class="header">Ändra konto information</h2>
  <form onsubmit={sendForm} if={opts.form}>
    <div>
      <default-input type="text" label="Telefon" name="phone" ref="phone" value={opts.form.phone} error={errors.phone} oninput={validateinput}
        onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="email" label="E-post" name="email" ref="email" value={opts.form.email} error={errors.email} oninput={validateinput}
        onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Webbplats" name="website" ref="website" value={opts.form.website} error={errors.website} oninput={validateinput}
        onblur={validate}></default-input>
    </div>
    <div class="checkbox">
      <label for="showInResellerList">Visa i återförsäljarlista</label>
      <input id="showInResellerList" type="checkbox" name="showInResellerList" ref="showInResellerList" checked={opts.form.showInResellerList} oninput={resellerListChange}></input>
    </div>
    <h2>Byt lösenord</h2>
    <div>
      <default-input type="password" label="Nuvarande Lösenord" name="currentPassword" ref="currentPassword" value={opts.form.currentPassword} error={errors.currentPassword} oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="password" label="Nytt Lösenord" name="newPassword" ref="newPassword" value={opts.form.newPassword} error={errors.newPassword} oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="password" label="Bekräfta Lösenord" name="confirmNewPassword" ref="confirmNewPassword" value={opts.form.confirmNewPassword} error={errors.confirmNewPassword}
        oninput={validateinput} onblur={validate}></default-input>
    </div>
    <h2 class="header">Fakturerings information</h2>
    <div>
      <default-input type="text" label="Namn" name="name" ref="name" value={opts.form.name} error={errors.name} oninput={validateinput}
        onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Adress1" name="address1" ref="address1" value={opts.form.address1} error={errors.address1}
        oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Address2" name="address2" ref="address2" value={opts.form.address2} error={errors.Address2}
        oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Postnummer" name="zipcode" ref="zipCode" value={opts.form.zipcode} error={errors.zipcode}
        oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input type="text" label="Stad" name="city" ref="city" value={opts.form.city} error={errors.city} oninput={validateinput}
        onblur={validate}></default-input>
    </div>
    <div class="country">
      <label for="countires">Land</label>
      <select name="countrycode" ref="countrycode" error={errors.countrycode} onchange={validateinput}>
        <option each={country in opts.countries} value={country.CountryCode} selected={country.CountryCode===opts.form.countrycode}>{country.CountryName}</option>
      </select>
    </div>
    <div>
      <button class="defaultButton" type="submit">Uppdatera information</button>
    </div>
  </form>
  <script>
    import './style.scss'
    import accountValidationRules from './accountValidationRules'
    import {
      accountFormChanged,
      updateAccountInfo,
      setInitialAccountInfo
    } from '../duck'
    import {
      validateObject,
      validate
    } from 'lib/validator'
    this.errors = {}

    const mapStateToOpts = state => {
      return {
        user: state.login.user,
        form: state.profile.accountForm,
        countries: state.app.countries,
        errorMessage: state.profile.errorMessage
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.validate = (e) => {
      const {
        name,
        value
      } = e.target

      const validationObj = {
        ...this.opts.form
      }

      let validation = validate(accountValidationRules[name], value, validationObj)
      this.errors[name] = validation.error
    }

    this.resellerListChange = (e) => {
      const {
        name,
        checked
      } = e.target

      this.opts.dispatch(accountFormChanged(name, checked))
    }

    this.validateinput = (e) => {
      const {
        name,
        value
      } = e.target

      this.opts.dispatch(accountFormChanged(name, value))

      if (this.errors[name] && this.errors[name].length) {
        const validation = validate(accountValidationRules[name], value)
        this.errors[name] = validation.error
      }
    }

    this.sendForm = (e) => {
      e.preventDefault()
      e.preventUpdate = true

      const formData = {
        ...this.opts.form
      }

      const validate = validateObject(accountValidationRules, formData)
      if (!validate.valid) {
        this.errors = validate.properties
        this.update()
        return
      } else {
        this.errors = {}
      }

      if (this.opts.user) {
        const payload = {
          ...this.opts.form,
        }

        this.opts.dispatch(updateAccountInfo(payload))
      }
    }

    this.on('update', () => {
      if(this.opts.errorMessage && this.opts.errorMessage === '401') {
        this.errors.currentPassword = 'Fel lösenord angivet'
      }
    })

    this.on('before-mount', () => {
      if(this.opts.form != null)
        return;
        
      const address = this.opts.user.InvoiceAddress
      const user = this.opts.user

      const accountInfo = {
        phone: user.Telephone,
        email: user.Email,
        website: user.Website,
        showInResellerList: user.ShowInResellerList,
        name: address.Name,
        address1: address.Address1,
        address2: address.Address2,
        zipcode: address.ZipCode,
        city: address.City,
        countrycode: address.CountryCode,
      }

      this.opts.dispatch(setInitialAccountInfo(accountInfo))
    })
  </script>
</account-form>