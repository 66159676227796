import {
  combineReducers
} from 'redux'
import main from 'tags/features/sliders/main-slider/duck'
import products from 'tags/features/sliders/product-slider/duck'

const reducer = combineReducers({
  main,
  products
})

export default reducer