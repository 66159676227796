<single-slider>
  <div ref="slider" class="slider">
    <div class="container" style="{wrapperStyles}">
      <div class="imagewrapper {onclick: hasOnClock}" style="{itemStyles}" each={image in images}>
        <img src={apiUrl}{endpoint}{image} alt={alt} onclick={onImageClicked} />
      </div>
    </div>
    <div class="left-arrow" if={images.length> 1}>
      <div class="button" onclick="{ showPrevious }">
        <div class="arrow"></div>
      </div>
    </div>
    <div class="right-arrow" if={images.length> 1}>
      <div class="button" onclick="{ showNext }">
        <div class="arrow"></div>
      </div>
    </div>
    <div class="dots" if={images.length> 1}>
      <div each="{dot, i in images}" onclick="{dotClick}" class="dot {active: (i === currentItem)}"></div>
    </div>
  </div>
  <script>
    import './style.scss'
    this.apiUrl = process.env.API_URL
    this.images = opts.images || []
    this.defaultImage = opts.defaultimage || null
    this.endpoint = opts.endpoint || ''
    this.alt = opts.alt || ''
    this.calculated = false
    this.itemWidth = null
    this.currentItem = 0
    this.hasOnClock = this.opts.onimageclicked !== undefined

    this.onImageClicked = (e) => {
      if(this.opts.onimageclicked)
        this.opts.onimageclicked(e.item)
    }

    this.wrapperStyles = {
      width: '0px',
      transform: 'translateX(0px)'
    }

    this.itemStyles = {
      width: '0px'
    }

    this.calculateSize = () => {
      if (!this.refs.slider)
        return

      const rect = this.refs.slider.getBoundingClientRect()
      this.itemWidth = rect.width
      this.calculated = true

      this.itemStyles.width = this.itemWidth + 'px'
      this.itemStyles.height = this.itemWidth + 'px'
      this.wrapperStyles.width = (this.itemWidth * this.images.length) + 'px'
    }

    this.calculatePosition = () => {
      this.wrapperStyles.transform = 'translateX(-' + this.currentItem * this.itemWidth + 'px)'
    }

    this.showNext = () => {
      if (!this.calculated)
        return

      if (this.currentItem === (this.images.length - 1))
        this.currentItem = 0
      else
        this.currentItem += 1

      this.calculatePosition()
    }

    this.showPrevious = () => {
      if (!this.calculated)
        return

      if (this.currentItem === 0)
        this.currentItem = this.images.length - 1
      else
        this.currentItem -= 1

      this.calculatePosition()
    }

    this.dotClick = (e) => {
      const item = e.item
      this.currentItem = item.i
      this.calculatePosition()
      this.update()
    }

    this.onResize = () => {
      this.calculateSize()
      this.calculatePosition()
      this.update()
    }

    this.on('mount', () => {
      if (this.defaultImage && this.images) {
        this.currentItem = this.images.findIndex(item => item === this.defaultImage)
      }

      window.addEventListener('resize', this.onResize, true)
      this.calculateSize()
      this.update()
    })
  </script>
</single-slider>