<main-slider>
  <image-slider images={state.images} loading={state.loading} />
  <script>
    import 'tags/common/image-slider'
    import {
      fetchImages
    } from './duck'
    this.mixin('store')
    this.state = this.store.getState().sliders.main

    this.store.subscribe(() => {
      const newState = this.store.getState().sliders.main
      if(newState !== this.state) {
        this.update({
          state: newState
        })
      }
    })

    this.on('mount', () => {
      if (!this.state.images) {
        this.store.dispatch(fetchImages())
      }
    })
  </script>
</main-slider>