<application-page>
  <product-slider />
  <div class="wrapper">
    <h3>Ansökan</h3>
    <div if={opts.webTexts}>
      <raw content={opts.webTexts.ApplicationInformation} />
    </div>
    <contact-feature />
  </div>
  <script>
    import './style.scss'
    import 'tags/features/contact-feature'
    import 'tags/features/sliders/product-slider'
    import 'tags/common/raw'

    const mapStateToOpts = state => {
      return {
        webTexts: state.app.texts
      }
    }

    this.reduxConnect(mapStateToOpts)
  </script>
</application-page>