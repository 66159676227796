import initialState from './initialState'

const FETCH_CART = 'iam/features/shoppingcart/FETCH_CART'
const FETCH_CART_SUCCESS = 'iam/features/shoppingcart/FETCH_CART_SUCCESS'
const FETCH_CART_FAILED = 'iam/features/shoppingcart/FETCH_CART_FAILED'

const ADD_TO_CART = 'iam/features/shoppingcart/ADD_TO_CART'
export const ADD_TO_CART_SUCCESS = 'iam/features/shoppingcart/ADD_TO_CART_SUCCESS'
const ADD_TO_CART_FAILED = 'iam/features/shoppingcart/ADD_TO_CART_FAILED'

const DELETE_CART_ITEM = 'iam/features/shoppingcart/DELETE_CART_ITEM'
const DELETE_CART_ITEM_SUCCESS = 'iam/features/shoppingcart/DELETE_CART_ITEM_SUCCESS'
const DELETE_CART_ITEM_FAILED = 'iam/features/shoppingcart/DELETE_CART_ITEM_FAILED'

const UPDATE_CART_ITEM = 'iam/features/shoppingcart/UPDATE_CART_ITEM'
const UPDATE_CART_ITEM_SUCCESS = 'iam/features/shoppingcart/UPDATE_CART_ITEM_SUCCESS'
const UPDATE_CART_ITEM_FAILED = 'iam/features/shoppingcart/UPDATE_CART_ITEM_FAILED'

const SHOW_WIDGET = 'iam/features/shoppingcart/SHOW_WIDGET'
const HIDE_WIDGET = 'iam/features/shoppingcart/HIDE_WIDGET'
const TOGGLE_WIDGET = 'iam/features/shoppingcart/TOGGLE_WIDGET'
const RESET_CART = 'iam/features/shoppingcart/RESET_CART'

const DELETE_CART = 'iam/features/shoppingcart/shoppingcart/DELETE_CART'
const DELETE_CART_SUCCESS = 'iam/features/shoppingcart/shoppingcart/DELETE_CART_SUCCESS'
const DELETE_CART_FAILED = 'iam/features/shoppingcart/shoppingcart/DELETE_CART_FAILED'

const SET_WAYOFDELIVERY = 'iam/features/shoppingcart/shoppingcart/SET_WAYOFDELIVERY'
const SET_DELIVERY_ADDRESS = 'iam/features/shoppingcart/shoppingcart/SET_DELIVERY_ADDRESS'
const SET_PARTIAL_DELIVERY = 'iam/features/shoppingcart/shoppingcart/SET_PARTIAL_DELIVERY'
const SET_ORDER_INFO_TEXT = 'iam/features/shoppingcart/shoppingcart/SET_ORDER_INFO_TEXT'

const SEND_ORDER = 'iam/features/shoppingcart/shoppingcart/SEND_ORDER'
const SEND_ORDER_SUCCESS = 'iam/features/shoppingcart/shoppingcart/SEND_ORDER_SUCCESS'
const SEND_ORDER_FAILED = 'iam/features/shoppingcart/shoppingcart/SEND_ORDER_FAILED'

const SEND_PRECHECK = 'iam/features/shoppingcart/shoppingcart/SEND_PRECHECK'
const SEND_PRECHECK_SUCCESS = 'iam/features/shoppingcart/shoppingcart/SEND_PRECHECK_SUCCESS'
const SEND_PRECHECK_FAILED = 'iam/features/shoppingcart/shoppingcart/SEND_PRECHECK_FAILED'

const RESET_ORDER_COMPLETE_STATE = 'iam/features/shoppingcart/shoppingcart/RESET_ORDER_COMPLETE_STATE'
const ERRORS = 'iam/features/shoppingcart/shoppingcart/ERRORS'

//Actions
export const orderInfoText = text => {
  return {
    type: SET_ORDER_INFO_TEXT,
    text
  }
}

export const setPartialDelivery = bool => {
  return {
    type: SET_PARTIAL_DELIVERY,
    bool
  }
}

export const setDeliveryAddress = id => {
  return {
    type: SET_DELIVERY_ADDRESS,
    id
  }
}

export const setWayOfDelivery = id => {
  return {
    type: SET_WAYOFDELIVERY,
    id
  }
}

export const setErrors = (name, value) => {
  return {
    type: ERRORS,
    name,
    value
  }
}

export const sendOrder = (payload) => {
  return {
    types: [SEND_ORDER, SEND_ORDER_SUCCESS, SEND_ORDER_FAILED],
    callApi: {
      endpoint: 'api/order',
      method: 'POST',
      data: payload
    }
  }
}

export const resetOrderCompleteState = state => {
  return {
    type: RESET_ORDER_COMPLETE_STATE,
    state
  }
}

export const sendPreCheck = payload => {
  return {
    types: [SEND_PRECHECK, SEND_PRECHECK_SUCCESS, SEND_PRECHECK_FAILED],
    callApi: {
      endpoint: 'api/OrderPreCheck',
      method: 'GET',
      data: payload
    }
  }
}

export const fetchCart = () => {
  return {
    types: [FETCH_CART, FETCH_CART_SUCCESS, FETCH_CART_FAILED],
    callApi: {
      endpoint: 'api/cart',
      method: 'GET'
    }
  }
}

export const addToCart = (articleId, quantity) => {
  const articleObj = {
    ArticleId: articleId,
    Quantity: quantity,
    QuickAdd: false
  }
  return {
    types: [ADD_TO_CART, ADD_TO_CART_SUCCESS, ADD_TO_CART_FAILED],
    callApi: {
      endpoint: 'api/cartrow',
      method: 'POST',
      data: articleObj
    }
  }
}

export const removeCartArticle = (Id) => {
  return {
    types: [DELETE_CART_ITEM, DELETE_CART_ITEM_SUCCESS, DELETE_CART_ITEM_FAILED],
    callApi: {
      endpoint: 'api/cartrow/' + Id,
      method: 'DELETE'
    }
  }
}
export const updateCartArticle = (Id, quantity) => {
  const quantityObj = {
    Quantity: quantity
  }
  return {
    types: [UPDATE_CART_ITEM, UPDATE_CART_ITEM_SUCCESS, UPDATE_CART_ITEM_FAILED],
    callApi: {
      endpoint: 'api/cartrow/' + Id,
      method: 'PUT',
      data: quantityObj
    }
  }
}

export const showWidget = () => {
  return {
    type: SHOW_WIDGET
  }
}


export const hideWidget = () => {
  return {
    type: HIDE_WIDGET
  }
}

export const toggleWidget = () => {
  return {
    type: TOGGLE_WIDGET
  }
}

export const removeCart = () => {
  return {
    types: [DELETE_CART, DELETE_CART_SUCCESS, DELETE_CART_FAILED],
    callApi: {
      endpoint: 'api/cart/',
      method: 'DELETE'
    }
  }
}

export const resetCart = () => {
  return {
    type: RESET_CART,
  }
}

//Reducer 
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_CART:
      return {
        ...state,
        loading: true
      }
    case FETCH_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case FETCH_CART_FAILED:
      return {
        ...state,
        loading: false,
      }
    case ADD_TO_CART:
      return {
        ...state,
        loading: true
      }
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
      }
    case ADD_TO_CART_FAILED:
      return {
        ...state,
        loading: false,
      }
    case DELETE_CART_ITEM:
      return {
        ...state,
        loading: true
      }
    case DELETE_CART_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case DELETE_CART_ITEM_FAILED:
      return {
        ...state,
        loading: false,
      }
    case UPDATE_CART_ITEM:
      return {
        ...state,
        loading: true
      }
    case UPDATE_CART_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case UPDATE_CART_ITEM_FAILED:
      return {
        ...state,
        loading: false,
      }
    case SHOW_WIDGET:
      return {
        ...state,
        showWidget: true
      }
    case HIDE_WIDGET:
      return {
        ...state,
        showWidget: false
      }
    case TOGGLE_WIDGET:
      return {
        ...state,
        showWidget: !state.showWidget
      }
    case DELETE_CART:
      return {
        ...state,
        loading: true
      }
    case DELETE_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data
      }
    case DELETE_CART_FAILED:
      return {
        ...state,
        loading: false,
      }
    case SET_WAYOFDELIVERY:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          wayOfDeliveryId: action.id
        }
      }
    case SET_PARTIAL_DELIVERY:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          partialDelivery: action.bool
        }
      }
    case SET_DELIVERY_ADDRESS:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          deliveryAddressId: action.id
        }
      }
    case SET_ORDER_INFO_TEXT:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          orderInfoText: action.text
        }
      }
    case ERRORS:
      return {
        ...state,
        errors: {
          ...state.errors,
          [action.name]: action.value
        }
      }
    case RESET_ORDER_COMPLETE_STATE:
      return {
        ...state,
        orderComplete: action.state
      }
    case SEND_ORDER:
      return {
        ...state,
        loading: true,
        orderError: false,
        orderComplete: false,
        hasOpenOrder: null
      }
    case SEND_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        orderComplete: true,
      }
    case SEND_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        orderError: true,
        orderComplete: false,
      }
    case SEND_PRECHECK:
      return {
        ...state,
        loading: true,
        hasOpenOrder: null
      }
    case SEND_PRECHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        hasOpenOrder: action.data
      }
    case SEND_PRECHECK_FAILED:
      return {
        ...state,
        loading: false,
        hasOpenOrder: false
      }
    case RESET_CART:
      return {
        ...state,
        data: {
          Id: 0,
          ShippingPrice: 0,
          VatAmount: 0,
          TotalExcludingVat: 0,
          TotalAmount: 0,
          IsAvailable: true,
          Rows: []
        }
      }
    default:
      return state
  }
}