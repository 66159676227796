import initialState from './initialState'
import {
  processTexts
} from 'redux/processors/webtexts'

const FETCH_SETTINGS = 'iam/app/FETCH_SETTINGS'
const FETCH_SETTINGS_SUCCESS = 'iam/app/FETCH_SETTINGS_SUCCESS'
const FETCH_SETTINGS_FAILED = 'iam/app/FETCH_SETTINGS_FAILED'

const FETCH_TEXTS = 'iam/app/FETCH_TEXTS'
const FETCH_TEXTS_SUCCESS = 'iam/app/FETCH_TEXTS_SUCCESS'
const FETCH_TEXTS_FAILED = 'iam/app/FETCH_TEXTS_FAILED'

const FETCH_COUNTRIES = 'iam/app/FETCH_COUNTRIES'
const FETCH_COUNTRIES_SUCCESS = 'iam/app/FETCH_COUNTRIES_SUCCESS'
const FETCH_COUNTRIES_FAILED = 'iam/app/FETCH_COUNTRIES_FAILED'

const FETCH_DELIVERY_WAYS = 'iam/app/FETCH_DELIVERY_WAYS'
const FETCH_DELIVERY_WAYS_SUCCESS = 'iam/app/FETCH_DELIVERY_WAYS_SUCCESS'
const FETCH_DELIVERY_WAYS_FAILED = 'iam/app/FETCH_DELIVERY_WAYS_FAILED'

//Actions
export const fetchSettings = () => {
  return {
    types: [FETCH_SETTINGS, FETCH_SETTINGS_SUCCESS, FETCH_SETTINGS_FAILED],
    callApi: {
      endpoint: 'api/settings',
      method: 'GET'
    }
  }
}

export const fetchWebtexts = () => {
  return {
    types: [FETCH_TEXTS, FETCH_TEXTS_SUCCESS, FETCH_TEXTS_FAILED],
    callApi: {
      endpoint: 'api/webtexts',
      method: 'GET',
      processData: processTexts
    }
  }
}

export const fetchCountries = () => {
  return {
    types: [FETCH_COUNTRIES, FETCH_COUNTRIES_SUCCESS, FETCH_COUNTRIES_FAILED],
    callApi: {
      endpoint: 'api/countries',
      method: 'GET',
    }
  }
}

export const fetchDeliveryWays = () => {
  return {
    types: [FETCH_DELIVERY_WAYS, FETCH_DELIVERY_WAYS_SUCCESS, FETCH_DELIVERY_WAYS_FAILED],
    callApi: {
      endpoint: 'api/wayofdelivery',
      method: 'GET',
    }
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_SETTINGS:
      return {
        ...state,
        loadingSettings: true
      }
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: action.data,
        loadingSettings: false
      }
    case FETCH_SETTINGS_FAILED:
      return {
        ...state,
        settings: null,
        loadingSettings: false
      }
    case FETCH_TEXTS:
      return {
        ...state,
      }
    case FETCH_TEXTS_SUCCESS:
      return {
        ...state,
        texts: action.data
      }
    case FETCH_TEXTS_FAILED:
      return {
        ...state,
        texts: null
      }
    case FETCH_COUNTRIES:
      return {
        ...state,
      }
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.data
      }
    case FETCH_COUNTRIES_FAILED:
      return {
        ...state,
        countries: null
      }
    case FETCH_DELIVERY_WAYS:
      return {
        ...state,
      }
    case FETCH_DELIVERY_WAYS_SUCCESS:
      return {
        ...state,
        deliveryWays: action.data
      }
    case FETCH_DELIVERY_WAYS_FAILED:
      return {
        ...state,
        deliveryWays: null
      }
    default:
      return state
  }
}