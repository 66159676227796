<scroll-top onclick={scrollToTop}>
  <div class="animated {hide: !shown, fadeIn: showScrollTop, fadeOut: !showScrollTop}">Tillbaks till toppen</div>
  <script>
    import './style.scss'
    this.shown = false
    this.showScrollTop = false

    this.setPosition = () => {
      var pageContent = document.querySelector('.page-holder')
      var pagePos = pageContent.getBoundingClientRect()

      var right = window.innerWidth - pagePos.right

      //If we have space to the right, move the icon outside the layout
      if (right > 100)
        right -= 100

      this.root.style.right = right + 'px'
    }

    this.onScroll = () => {
      const top = window.pageYOffset || document.documentElement.scrollTop
      const offset = 400
      if (top >= offset && !this.showScrollTop) {
        this.showScrollTop = true
        this.shown = true
        this.update()
      } else if (top < offset && this.showScrollTop) {
        this.showScrollTop = false
        this.update()
      }
    }

    this.scrollToTop = () => {
      var currentScroll = document.documentElement.scrollTop || document.body.scrollTop
      if (currentScroll > 0) {
        window.requestAnimationFrame(this.scrollToTop)
        window.scrollTo(0, currentScroll - (currentScroll / 5))
      }
    }

    this.on('mount', function () {
      this.setPosition()
      window.addEventListener('resize', this.setPosition)
      window.addEventListener('scroll', this.onScroll)
    })

    this.on('unmount', function () {
      window.removeEventListener('resize', this.setPosition)
      window.removeEventListener('scroll', this.onScroll)
    })
  </script>
</scroll-top>