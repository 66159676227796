export default {
  Name: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  Address1: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  Address2: {
    minLength: 2,
    maxLength: 40
  },
  ZipCode: {
    required: true,
    minLength: 5
  },
  City: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  CountryCode: {
    required: true,
    minLength: 2
  },
}