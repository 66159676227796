import initialState from './initialState'

const FETCH_RELATED_ARTICLES = 'iam/pages/article-page/FETCH_RELATED_ARTICLES'
const FETCH_RELATED_ARTICLES_SUCCESS = 'iam/pages/article-page/FETCH_RELATED_ARTICLES_SUCCESS'
const FETCH_RELATED_ARTICLES_FAILED = 'iam/pages/article-page/FETCH_RELATED_ARTICLES_FAILED'

//Actions
export const fetchRelatedProducts = id => {
  return {
    types: [FETCH_RELATED_ARTICLES, FETCH_RELATED_ARTICLES_SUCCESS, FETCH_RELATED_ARTICLES_FAILED],
    callApi: {
      endpoint: 'api/relatedarticles/' + id,
      method: 'GET'
    }
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch(action.type) {
    case FETCH_RELATED_ARTICLES: 
      return {
        ...state,
        loading: true
      }
    case FETCH_RELATED_ARTICLES_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case FETCH_RELATED_ARTICLES_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}