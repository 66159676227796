import initialState from './initialState'

const FETCH_RESELLERS = 'iam/features/resellers-feature/FETCH_RESELLERS'
const FETCH_RESELLERS_SUCCESS = 'iam/features/resellers-feature/FETCH_RESELLERS_SUCCESS'
const FETCH_RESELLERS_FAILED = 'iam/features/resellers-feature/FETCH_RESELLERS_FAILED'

//Actions
export const fetchResellers = () => {
  return {
    types: [FETCH_RESELLERS, FETCH_RESELLERS_SUCCESS, FETCH_RESELLERS_FAILED],
    callApi: {
      endpoint: 'api/resellers',
      method: 'GET'
    }
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_RESELLERS:
      return {
        ...state,
        loading: true
      }
    case FETCH_RESELLERS_SUCCESS:
      return {
        ...state,
        loading: false,
        items: action.data
      }
    case FETCH_RESELLERS_FAILED:
      return {
        ...state,
        loading: false
      }
    default:
      return state
  }
}