import initialState from './intialState'

const RESET = 'iam/features/resetpassword-feature/RESET'
const RESET_SUCCESS = 'iam/features/contact-feature/RESET_SUCCESS'
const RESET_FAILED = 'iam/features/contact-feature/RESET_FAILED'

// const FORM_CHANGED = 'iam/features/contact-feature/FORM_CHANGED'

//Actions
export const resetPassword = (email) => {
  const payload = {
    email: email
  }

  return {
    types: [RESET, RESET_SUCCESS, RESET_FAILED],
    callApi: {
      endpoint: 'api/resetpassword',
      method: 'POST',
      data: payload
    }
  }
}

//Reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        loading: true,
        sent: false
      }
    case RESET_SUCCESS:
      return {
        ...state,
        loading: false,
        sent: true
      }
    case RESET_FAILED:
      return {
        ...state,
        loading: false,
        error: true
      }
    default:
      return state
  }
}