/**
 * Local functions for test validation
 */
export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0
}

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0
}

export const isIntOrFloat = (n) => {
  var val = parseFloat(n)
  return isInt(val) || isFloat(val)
}

export const setErrorMessage = (response) => {
  if (typeof response.error === 'function') {
    response.error = response.error()
  }
}

export const isValidLuhnNumber = (value) => {
  if (/[^0-9-]+/.test(value)) return false

  // The Luhn Algorithm. It's so pretty.
  var nCheck = 0,
    nDigit = 0,
    bEven = false

  value = value.replace(/\D/g, '')

  for (var n = value.length - 1; n >= 0; n--) {
    var cDigit = value.charAt(n)
    nDigit = parseInt(cDigit, 10)

    if (bEven) {
      if ((nDigit *= 2) > 9) nDigit -= 9
    }

    nCheck += nDigit
    bEven = !bEven
  }

  return (nCheck % 10) === 0
}

/**
 * Validate a single value
 *
 * @param {object} validationRules {Validation settings to check with value}
 * @param {string} value {Value to check}
 * @param {object} [object={}] {Optional object with other values in form}
 * @returns {object} Object with property valid true/false and error message
 */
export const validate = (validationRules, value, object) => {
  object = object || []
  var response = {
    valid: true,
    error: ''
  }

  if (!validationRules)
    return response

  var isEmptyValue = (typeof value === 'string' && !value.trim().length) || typeof value === 'undefined' || value === null

  /*
    Required
  */
  var requiredFailed = false
  var isRequired = validationRules.required
  if (typeof validationRules.required === 'function') {
    isRequired = validationRules.required(object)
    if (isRequired && isEmptyValue) {
      requiredFailed = true
    }
  } else if (validationRules.required && isEmptyValue) {
    requiredFailed = true
  }

  if (requiredFailed) {
    response.valid = false
    response.error = validationRules.requiredMessage || validationRules.message || 'Detta fält krävs'
    setErrorMessage(response)
    return response
  }

  //not required and empty value, no need to continue
  if (!isRequired && isEmptyValue) {
    setErrorMessage(response)
    return response
  }

  //Strip whitespace
  if (validationRules.allowwhitspace) {
    value = value.replace(/\s/g, '')
  }

  /*
    Checkbox
  */
  if (validationRules.checkbox && isRequired) {
    if (!value) {
      response.valid = false
      response.error = validationRules.requiredMessage || validationRules.message || 'Detta fält krävs'
    }
  }

  /*
    RegEx
  */
  if (validationRules.regex) {
    const regEx = new RegExp(validationRules.regex)
    if (!regEx.test(value)) {
      response.valid = false
      response.error = validationRules.regexMessage || validationRules.message || 'Detta fält krävs'
    }
  }

  /*
    Email
  */
  if (validationRules.email) {
    const regEx = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    if (!regEx.test(value)) {
      response.valid = false
      response.error = validationRules.emailMessage || validationRules.message || 'Ange en giltig e-postadress'
    }
  }

  /*
   * Min length
   */
  if (validationRules.minLength > 0) {
    if (value.length < validationRules.minLength) {
      response.valid = false
      response.error = validationRules.lengthMessage || validationRules.message || 'För kort text'
    }
  }

  /*
   * Max length
   */
  if (validationRules.maxLength > 0) {
    if (value.length > validationRules.maxLength) {
      response.valid = false
      response.error = validationRules.lengthMessage || validationRules.message || 'För lång text'
    }
  }

  /*
   * Min amount
   */
  if (validationRules.minAmount > 0) {
    if (value < validationRules.minAmount) {
      response.valid = false
      response.error = validationRules.amountMessage || validationRules.message || 'För litet värde'
    }
  }

  /*
   * Max amount
   */
  if (validationRules.maxAmount > 0) {
    if (value > validationRules.maxAmount) {
      response.valid = false
      response.error = validationRules.amountMessage || validationRules.message || 'För stort värde'
    }
  }

  /*
   * Match
   */
  if (validationRules.match) {
    if (value !== object[validationRules.match]) {
      response.valid = false
      response.error = validationRules.matchMessage || validationRules.message || 'Värdet matchar inte'
    }
  }

  /**
   * Maxlines
   */
  if (validationRules.maxLines) {
    var lineCount = value.split('\n')
    if (lineCount.length > validationRules.maxLines) {
      response.valid = false
      response.error = validationRules.linesMessage || validationRules.message || 'Detta fält krävs'
    }
  }

  /**
   * Number
   */
  if (validationRules.number) {
    if (!isIntOrFloat(value)) {
      response.valid = false
      response.error = validationRules.numberMessage || validationRules.message || 'Värdet är inte ett nummer'
    }
  }

  /*
   * Int
   */
  if (validationRules.integer) {
    if (!isInt(value)) {
      response.valid = false
      response.error = validationRules.integerMessage || validationRules.message || 'Värdet är inte ett nummer'
    }
  }

  /*
   * Decimal
   */
  if (validationRules.decimal) {
    if (!isFloat(value)) {
      response.valid = false
      response.error = validationRules.decimalMessage || validationRules.message || 'Värdet är inte ett nummer'
    }
  }

  /*
   * Swedish ZipCode
   */
  if (validationRules.swedishZipCode) {
    const regEx = new RegExp(/^\d{3}[\s]{0,1}\d{2}$/)
    if (!regEx.test(value)) {
      response.valid = false
      response.error = validationRules.zipCodeMessage || validationRules.message || 'Detta fält krävs'
    }
  }

  /*
   * Identity
   */
  if (validationRules.identity) {
    var number = value.replace('-', '').replace(' ', '').trim()
    if (number.length !== 10 || !isValidLuhnNumber(number)) {
      response.valid = false
      response.error = validationRules.identityMessage || validationRules.message || 'Ogiltigt personnummer'
    }
  }

  setErrorMessage(response)

  return response
}

/**
 * Validate object with values
 *
 * @param {object} validationRules {Validation rules for object values}
 * @param {object} object {Values to check}
 * @param {array} [propertiesToValidate=[]] {Optional string array with properties to check, if empty all validation is checked}
 * @param {object} [customErrors={}] {Optional object with errors}
 * @returns {object} {Property valid true/false and properties with error messages}
 */
export const validateObject = (validationRules, object, propertiesToValidate, customErrors) => {
  propertiesToValidate = propertiesToValidate || []
  customErrors = customErrors || {}
  var response = {
    valid: true,
    properties: {}
  }

  Object.keys(validationRules).forEach(function (property) {
    if (propertiesToValidate.length > 0 && propertiesToValidate.indexOf(property) < 0)
      return

    var rules = validationRules[property]
    var value = object[property]
    var validation = validate(rules, value, object)

    if (customErrors !== undefined && customErrors[property] !== undefined) {
      if (customErrors[property].length > 0) {
        response.valid = false
        validation.valid = false
        validation.error = customErrors[property]
      }
    }

    if (!validation.valid) {
      response.valid = false
      response.properties[property] = validation.error
    }
  })

  return response
}