<contact-feature>
  <h2>Ny kund förfrågan</h2>
  <form onsubmit={onSubmit}>
    <div class="row">
      <div class="col-xs-12 col-sm-4">
        <default-input label="Företag"
          ref="company" name="company" value={opts.form.company} error={errors.company} required={true} oninput={validateinput} onblur={validate} />
      </div>
      <div class="col-xs-12 col-sm-4">
        <default-input label="Organisationsnummer" name="identity" ref="identity" value={opts.form.identity} error={errors.identity} oninput={validateinput} onblur={validate}
          required={true} />
      </div>
      <div class="col-xs-12 col-sm-4">
        <default-input label="Webbplats" ref="website" name="website" value={opts.form.website} error={errors.website} oninput={validateinput} onblur={validate} required={true} />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-4">
        <default-input label="Adress" ref="address" name="address" value={opts.form.address} error={errors.address} required={true} oninput={validateinput} onblur={validate} />
      </div>
      <div class="col-xs-12 col-sm-4">
        <default-input label="Postnummer" ref="zipcode" name="zipcode" value={opts.form.zipcode} error={errors.zipcode} required={true} oninput={validateinput} onblur={validate} />
      </div>
      <div class="col-xs-12 col-sm-4">
        <default-input label="Stad" ref="city" name="city" value={opts.form.city} error={errors.city} required={true} oninput={validateinput} onblur={validate} />
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-4">
        <default-input label="Kontaktperson" ref="name" name="name" error={errors.name} value={opts.form.name} oninput={validateinput} onblur={validate} required={true} />
      </div>
      <div class="col-xs-12 col-sm-4">
        <default-input label="Email" ref="email" name="email" value={opts.form.email} error={errors.email} oninput={validateinput} onblur={validate} required={true} />
      </div>
      <div class="col-xs-12 col-sm-4">
        <default-input label="Telefon" ref="telephone" name="telephone" value={opts.form.telephone} error={errors.telephone} oninput={validateinput} onblur={validate}
          required={true} />
      </div>
    </div>
    <default-textarea label="Meddelande" ref="message" name="message" value={opts.form.message} error={errors.message} oninput={validateinput} onblur={validate} />
    <div class="complete" if={opts.sent}>
      {opts.texts.ContactComplete}
    </div>
    <div class="errorMessage" if={opts.error}>
      Misslyckades med att skicka meddelandet. Var vänlig och försök igen!
    </div>
    <div class="buttonWrapper">
      <button type="submit" class="whiteBtn">skicka meddelande</button>
    </div>
    <loading-overlay if={opts.loading} />
  </form>
  <script>
    import './style.scss'
    import 'tags/common/inputs/default-input'
    import 'tags/common/inputs/default-textarea'
    import 'tags/common/loading-overlay'
    import {
      validateObject,
      validate
    } from 'lib/validator'
    import validationRules from './validationRules'
    import {
      sendContact,
      formChanged
    } from './duck'
    this.errors = {}

    const mapStateToOpts = state => {
      return {
        loading: state.contact.loading,
        sent: state.contact.sent,
        form: state.contact.form,
        error: state.contact.error,
        texts: state.app.texts
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.onSubmit = e => {
      e.preventDefault()

      const payload = {
        ...this.opts.form
      }

      const validate = validateObject(validationRules, payload)

      if (!validate.valid) {
        this.errors = validate.properties
        return
      } else {
        this.errors = {}
      }

      this.opts.dispatch(sendContact(payload))
    }

    this.validate = (e) => {
      const {
        name,
        value
      } = e.target

      const validation = validate(validationRules[name], value)
      this.errors[name] = validation.error
    }

    this.validateinput = (e) => {
      const {
        name,
        value
      } = e.target

      this.opts.dispatch(formChanged(name, value))

      if (this.errors[name] && this.errors[name].length) {
        const validation = validate(validationRules[name], value)
        this.errors[name] = validation.error
      }
    }
  </script>
</contact-feature>