<contact-page>
  <div>
    <div class="image">
      <div class="images">
        <div each={image in opts.images}>
          <img src="{apiUrl}/api/webimage/{image.Id}" alt="Contact">
          <div class="text">
            <raw content={nl2br(image.Text)} />
          </div>
        </div>
      </div>
      <h2>{opts.settings.Name}</h2>
    </div>
    <div class="info row">
      <div class="contact col-xs-12 col-sm-2">
        <h3 class="subHeaderText">Kontakta Oss</h3>
        <span>{opts.settings.Name}</span>
        <span>{opts.settings.Address}</span>
        <span>{opts.settings.ZipCode} {opts.settings.City}</span>
        <span>Telefon: {opts.settings.Telephone}</span>
        <span>E-mail: {opts.settings.Email}</span>
      </div>
      <div class="open col-xs-12 col-sm-3">
        <h3 class="subHeaderText">Våra Öppettider</h3>
        <span if={opts.webTexts}>
          <raw content={nl2br(opts.webTexts.Openinghours)} />
        </span>
        <div class="resellerAndSocial">
          <div class="resellerInfo">
            <div>För att bli återförsäljare kontakta oss,</div>
            <div>så får ni inloggningsuppgifter till webshopen.</div>
          </div>
          <span class="follow">Följ oss på facebook och instagram</span>
          <span class="socialMedia">
            <a href="https://www.facebook.com/IAMinteriorAB" target="_blank">
              <span>
                <raw content={faceBookMini} />
              </span>
            </a>
            <a href="https://www.instagram.com/iaminterior.se" target="_blank">
              <span>
                <raw content={instagramMini} />
              </span>
            </a>
        </div>
      </div>
      <div class="row col-xs-12 col-sm-7">
        <div class="contact col-xs-12 col-sm-4">
          <span if={opts.webTexts}>
            <raw content={nl2br(opts.webTexts.ContactSouth)} />
          </span>
        </div>
        <!-- <div class="contact col-xs-12 col-sm-4">
          <span if={opts.webTexts}>
            <raw content={nl2br(opts.webTexts.ContactNorthMiddle)} />
          </span>
        </div> -->
        <div class="contact col-xs-12 col-sm-4">
          <span if={opts.webTexts}>
            <raw content={nl2br(opts.webTexts.ContactNorth)} />
          </span>
        </div>
        <div class="contact col-xs-12 col-sm-4">
          <span if={opts.webTexts}>
            <raw content={nl2br(opts.webTexts.ContactDesign)} />
          </span>
        </div>
        <div class="contact col-xs-12 col-sm-4">
          <span if={opts.webTexts}>
            <raw content={nl2br(opts.webTexts.ContactPurchase)} />
          </span>
        </div>
      </div>
    </div>
    <contact-feature />
  </div>
  <script>
    import './style.scss'
    import 'tags/common/raw'
    import glas from 'images/siteimages/glas.jpg'
    import faceBookMini from 'images/svg/facebook-round.svg'
    import instagramMini from 'images/svg/instagram-round.svg'
    import 'tags/features/contact-feature'
    import {
      fetchImages
    } from './duck'
    import {
      nl2br
    } from 'lib/utils'
    this.mixin('store')

    this.glas = glas
    this.faceBookMini = faceBookMini
    this.instagramMini = instagramMini
    this.webTexts = this.store.getState().app.texts
    this.images = this.store.getState().contactpage.images
    this.apiUrl = process.env.API_URL
    this.nl2br = nl2br

    const mapStateToOpts = state => {
      return {
        images: state.contactpage.images,
        loading: state.contactpage.loading,
        settings: state.app.settings.Company,
        webTexts: state.app.texts
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.on('mount', () => {
      if (!this.images) {
        this.store.dispatch(fetchImages())
      }
    })
  </script>
</contact-page>