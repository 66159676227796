<site-footer>
  <footer>
    <div class="copyright">
    &copy; {year} I AM interior - Hemsida producerad av<a href="http://barnstenit.se/" target="_blank"> Bärnsten IT</a>      
    </div>
  </footer>
  <script>
    import './style.scss'
    this.year = null

    this.on('mount', () => {
      const date = new Date()
      this.year = date.getFullYear()
      this.update()
    })
  </script>
</site-footer>