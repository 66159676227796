<profile-page>
  <div class="row">
    <div class="col-xs-12 col-sm-4">
      <div class="userInfo">
        <account-form></account-form>
      </div>
    </div>
    <div class="changeDeliveryAddress col-xs-12 col-sm-4">
      <delivery-form></delivery-form>
      <reseller-form></reseller-form>
    </div>
    <div class="addDeliveryAddress col-xs-12 col-sm-4">
      <add-delivery-form></add-delivery-form>
    </div>
    <loading-overlay if={opts.loading} />
  </div>
  <script>
    import './style.scss'
    import 'tags/common/inputs/default-input'
    import 'tags/common/loading-overlay'
    import './account-form'
    import './delivery-form'
    import './add-delivery-form'
    import './reseller-form'

    const mapStateToOpts = state => {
      return {
        loading: state.profile.loading
      }
    }

    this.reduxConnect(mapStateToOpts)
  </script>
</profile-page>