<reseller-form>
  <form onsubmit={onSubmit}>
    <h2 class="header">Besöksadress</h2>
    <div>
      <default-input type="text" label="Adress" name="Address" ref="address1" value={opts.form.Address}
        error={errors.Address} oninput={onChange} onblur={validate} />
    </div>
    <div>
      <default-input type="text" label="Postnummer" name="ZipCode" ref="zipCode" value={opts.form.ZipCode}
        error={errors.ZipCode} oninput={onChange} onblur={validate} />
    </div>
    <div>
      <default-input type="text" label="Stad" name="City" ref="city" value={opts.form.City} error={errors.City}
        oninput={onChange} onblur={validate}></default-input>
    </div>
    <div class="country">
      <label for="countires">Land</label>
      <select name="CountryCode" ref="countrycode" error={errors.CountryCode} onchange={onChange}>
        <option each={country in opts.countries} value={country.CountryCode} selected={country.CountryCode===opts.form.CountryCode}>{country.CountryName}</option>
      </select>
    </div>
    <div>
      <default-input type="text" label="Telefon" name="Telephone" ref="city" value={opts.form.Telephone} error={errors.Telephone}
        oninput={onChange} onblur={validate}></default-input>
    </div>
    <div>
      <button class="defaultButton" type="submit">Uppdatera besöksadress</button>
    </div>
  </form>
  <script>
    import validationRules from './validationRules'
    import {
      validateObject,
      validate
    } from 'lib/validator'
    import {
      fetchVisitingAddress,
      setVisitingAddress,
      updateVisitingAddress
    } from '../duck'
    this.errors = {}

    const mapStateToOpts = state => {
      return {
        user: state.login.user,
        countries: state.app.countries,
        form: state.profile.visitingAddress
      }
    }
    this.reduxConnect(mapStateToOpts)

    this.onChange = e => {
      const {
        name,
        value
      } = e.target;

      this.opts.dispatch(setVisitingAddress(name, value));
    }

    this.validate = (e) => {
      const {
        name,
        value
      } = e.target

      const validationObj = {
        ...this.opts.form
      }

      let validation = validate(validationRules[name], value, validationObj)
      this.errors[name] = validation.error
    }

    this.onSubmit = e => {
      e.preventDefault();


      const validate = validateObject(validationRules, this.opts.form)
      if (!validate.valid) {
        this.errors = validate.properties;
        this.update();
        return;
      } else {
        this.errors = {}
      }

      this.opts.dispatch(updateVisitingAddress(this.opts.form));
    }

    this.on('mount', () => {
      this.opts.dispatch(fetchVisitingAddress())
    })

  </script>
</reseller-form>