import initialState from './initialState'
import {
  COMMON_LOGGEDOUT
} from 'redux/commonTypes'

const FETCH_ORDERS = 'iam/features/orders/FETCH_ORDERS'
const FETCH_ORDERS_SUCCESS = 'iam/features/orders/FETCH_ORDERS_SUCCESS'
const FETCH_ORDERS_FAILED = 'iam/features/orders/FETCH_ORDERS_FAILED'

const FETCH_ORDER_ROWS = 'iam/features/orders/FETCH_ORDER_ROWS'
const FETCH_ORDER_ROWS_SUCCESS = 'iam/features/orders/FETCH_ORDER_ROWS_SUCCESS'
const FETCH_ORDER_ROWS_FAILED = 'iam/features/orders/FETCH_ORDER_ROWS_FAILED'

//Actions
export const fetchOrders = (page) => {
  const takeCount = 30
  const payload = {
    skip: takeCount * (page - 1),
    take: 30
  }

  return {
    types: [FETCH_ORDERS, FETCH_ORDERS_SUCCESS, FETCH_ORDERS_FAILED],
    callApi: {
      endpoint: 'api/orders',
      method: 'GET',
      data: payload
    },
    page,
    take: payload.take
  }
}

export const fetchOrderRows = orderId => {
  return {
    types: [FETCH_ORDER_ROWS, FETCH_ORDER_ROWS_SUCCESS, FETCH_ORDER_ROWS_FAILED],
    callApi: {
      endpoint: 'api/OrderRows/' + orderId,
      method: 'GET',
    },
    orderId
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case COMMON_LOGGEDOUT:
      return {
        ...state,
        data: null,
        currentPage: 1,
        viewOrderRows: [],
        orderRows: {}
      }
    case FETCH_ORDERS:
      return {
        ...state,
        loading: true,
        currentPage: action.page,
        take: action.take
      }
    case FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.data,
        viewOrderRows: [],
        orderRows: {}
      }
    case FETCH_ORDERS_FAILED:
      return {
        ...state,
        loading: false
      }
    case FETCH_ORDER_ROWS:
      return {
        ...state,
        loadingRows: true,
      }
    case FETCH_ORDER_ROWS_SUCCESS:
      return {
        ...state,
        loadingRows: false,
        orderRows: {
          ...state.orderRows,
          [action.orderId]: action.data
        },
        viewOrderRows: [
          ...state.viewOrderRows,
          action.orderId
        ]
      }
    case FETCH_ORDER_ROWS_FAILED:
      return {
        ...state,
        loadingRows: false
      }
    default:
      return state
  }
}