<delivery-form>
  <h2 class="header">Ändra leverans information</h2>
  <form onsubmit={updateDeliveryInfo}>
    <div class="existingAddresses">
      <select onchange={addressSelected} name="deliveryAddress" ref="addressId">
        <option value="" disabled selected>Välj adress...</option>
        <option each={address in opts.user.DeliveryAddresses} value={address.Id}>{address.Name}, {address.Address1}, {address.City}</option>
      </select>
    </div>
    <div>
      <default-input disabled={deliveryAddressId ? false : true} type="text" label="Namn" name="Name" ref="name" value={opts.form.Name}
        error={errors.Name} oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input disabled={deliveryAddressId ? false : true} type="text" label="Adress1" name="Address1" ref="address1" value={opts.form.Address1}
        error={errors.Address1} oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input disabled={deliveryAddressId ? false : true} type="text" label="Address2" name="Address2" ref="address2" value={opts.form.Address2}
        error={errors.Address2} oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input disabled={deliveryAddressId ? false : true} type="text" label="Postnummer" name="ZipCode" ref="zipCode" value={opts.form.ZipCode}
        error={errors.ZipCode} oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div>
      <default-input disabled={deliveryAddressId ? false : true} type="text" label="Stad" name="City" ref="city" value={opts.form.City} error={errors.City}
        oninput={validateinput} onblur={validate}></default-input>
    </div>
    <div class="country">
      <label for="countires">Land</label>
      <select disabled={deliveryAddressId ? false : true} name="CountryCode" ref="countrycode" error={errors.CountryCode} onchange={validateinput}>
        <option each={country in opts.countries} value={country.CountryCode} selected={country.CountryCode===opts.form.CountryCode ? country.CountryCode===opts.form.CountryCode : country.CountryCode==='SE'}>{country.CountryName}</option>
      </select>
    </div>
    <div class="defaultAddress">
      <label for="defaultaddress">Standard leveransadress</label>
      <input disabled={deliveryAddressId ? false : true} type="checkbox" name="defaultaddress" ref="defaultAddress" checked={opts.form.DefaultAddress}></input>
    </div>
    <div>
      <button class="defaultButton" type="submit">Uppdatera adress</button>
    </div>
  </form>
  <script>
    import './style.scss'
    import deliveryValidationRules from './deliveryValidationRules'
    import {
      deliveryFormChanged,
      updateDeliveryInfo,
      setDeliveryInfo
    } from '../duck'
    import {
      validateObject,
      validate
    } from 'lib/validator'
    this.errors = {}
    this.deliveryAddressId = null

    const mapStateToOpts = state => {
      return {
        user: state.login.user,
        countries: state.app.countries,
        form: state.profile.deliveryForm
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.validate = (e) => {
      const {
        name,
        value
      } = e.target

      let validation = validate(deliveryValidationRules[name], value)
      this.errors[name] = validation.error
    }

    this.validateinput = (e) => {
      const {
        name,
        value
      } = e.target

      this.opts.dispatch(deliveryFormChanged(name, value))

      if (this.errors[name] && this.errors[name].length) {
        const validation = validate(deliveryValidationRules[name], value)
        this.errors[name] = validation.error
      }
    }

    this.updateDeliveryInfo = (e) => {
      e.preventDefault()
      e.preventUpdate = true

      const payload = {
        ...this.opts.form,
        DefaultAddress: this.refs.defaultAddress.checked
      }

      const validate = validateObject(deliveryValidationRules, payload)

      if (!validate.valid) {
        this.errors = validate.properties
        this.update()
        return
      } else {
        this.errors = {}
      }

      if (this.deliveryAddressId) {
        this.opts.dispatch(updateDeliveryInfo(this.deliveryAddressId, payload))
      }
    }

    this.addressSelected = () => {
      this.deliveryAddressId = this.refs.addressId.value

      const selectedAddress = this.opts.user.DeliveryAddresses.find(address => {
        return address.Id === Number(this.deliveryAddressId)
      })

      if (selectedAddress)
        this.opts.dispatch(setDeliveryInfo(selectedAddress))
    }
  </script>
</delivery-form>