<multi-slider>
  <div class="container">
    <div class="group" each={group, i in groups}>
      <vertical-slider images={group.images} currentimage={group.currentImage} endpoint={endpoint} showcount={group.showcount} />
    </div>
    <loading-spinner if={opts.loading} />
  </div>
  <script>
    import './style.scss'
    import 'tags/common/multi-slider/vertical-slider'
    import 'tags/common/loading-spinner'
    this.images = opts.images || []
    this.switchInterval = opts.interval || 2500
    this.endpoint = opts.endpoint || 'api/webimage/'
    this.groupsCount = 5
    this.currentGroup = null
    this.timer = null
    this.groups = null

    this.calculateGroups = () => {
      if (!this.images.length || this.groups) return

      const count = this.images.length
      this.groups = []

      const groupBy = Math.floor(count / this.groupsCount)
      let restCount = count - (groupBy * this.groupsCount)
      let restAdded = 0

      for (let i = 0; i < this.groupsCount; i++) {
        let indCalc = i * groupBy
        const index = restAdded > 0 ? indCalc + restAdded : indCalc
        const addRest = restCount > 0 ? 1 : 0

        if (addRest) {
          restCount--
          restAdded++
        }

        const images = this.images.slice(index, index + groupBy + addRest)

        const object = {
          images: images,
          currentImage: 0,
          showcount: i === 1 || i === 3 ? 1 : 2
        }

        this.groups.push(object)
      }

      this.update()
    }

    this.showNext = () => {
      if (!this.groups)
        return

      if (this.currentGroup === (this.groups.length - 1) || this.currentGroup === null)
        this.currentGroup = 0
      else
        this.currentGroup += 1

      const group = this.groups[this.currentGroup]
      const currentImage = group.currentImage

      if (currentImage === (group.images.length - group.showcount))
        group.currentImage = 0
      else
        group.currentImage += 1

      this.update()
    }

    this.startTimer = () => {
      if (!this.timer) {
        this.timer = setInterval(() => {
          this.showNext()
          this.update()
        }, this.switchInterval)
      }
    }

    this.stopTimer = () => {
      if (this.timer) {
        clearInterval(this.timer)
        this.timer = null
      }
    }

    this.on('update', () => {
      const triggerCalculate = this.images !== opts.images
      this.images = opts.images || []

      if (triggerCalculate) this.calculateGroups()
    })

    this.on('unmount', () => {
      this.stopTimer()
    })

    this.on('mount', () => {
      this.calculateGroups()
      this.startTimer()
    })
  </script>
</multi-slider>