<app>
  <site-header></site-header>
  <site-navigation currentpage={this.currentPage} />
  <div class="page-holder">
    <router if={allowRoute}>
      <route path="/">
        <start-page />
      </route>
      <route path="aboutus">
        <aboutus-page />
      </route>
      <route path="information">
        <information-page />
      </route>
      <route path="contact">
        <contact-page />
      </route>
      <route path="cart">
        <shoppingcart-feature />
      </route>
      <route path="checkout">
        <checkout-feature />
      </route>
      <route path="shop">
        <shop-page />
      </route>
      <route path="shop/*/*">
        <shop-page />
      </route>
      <route path="login">
        <login-page />
      </route>
      <route path="article/*/*">
        <article-page />
      </route>
      <route path="reseller">
        <resellers-page />
      </route>
      <route path="application">
        <application-page />
      </route>
      <route path="resetpassword">
        <resetpassword-page />
      </route>
      <route path="imagesdl">
        <imagesdl-page />
      </route>
      <route path="profile">
        <profile-page />
      </route>
      <route path="orders">
        <orders-feature />
      </route>
      <route path="*">
        <notfound-page />
      </route>
    </router>
  </div>
  <scroll-top />
  <site-footer />
  <script>
    import route from 'riot-route'
    import {
      fetchSettings,
      fetchWebtexts,
      fetchCountries,
      fetchDeliveryWays
    } from './duck'
    import {
      checkLogin,
      fetchUser
    } from 'tags/features/login-feature/duck'
    import {
      fetchProductGroups
    } from 'tags/features/products/categories/duck'
    import 'riot-route/lib/tag'
    import 'normalize.css'
    import 'css/base'
    import './style.scss'
    import 'tags/common/scroll-top'

    //Nav
    import 'tags/layout/site-navigation'
    import 'tags/layout/site-header'
    import 'tags/layout/site-footer'

    //pages
    import 'tags/pages/notfound-page'
    import 'tags/pages/start-page'
    import 'tags/pages/information-page'
    import 'tags/pages/aboutus-page'
    import 'tags/pages/contact-page'
    import 'tags/pages/shop-page'
    import 'tags/pages/login-page'
    import 'tags/pages/article-page'
    import 'tags/features/shoppingcart/shoppingcart-feature'
    import 'tags/pages/resellers-page'
    import 'tags/pages/application-page'
    import 'tags/pages/resetpassword-page'
    import 'tags/pages/imagesdl-page'
    import 'tags/pages/profile-page'
    import 'tags/features/orders-feature'

    this.currentPage = ''
    this.allowRoute = false
    this.loggedIn = false;

    const requireLogin = ['shop', 'article', 'cart', 'checkout', 'imagesdl', 'account', 'orders']

    this.on('before-mount', () => {
      this.opts.dispatch(checkLogin())
      this.opts.dispatch(fetchSettings())
      this.opts.dispatch(fetchWebtexts())
      this.opts.dispatch(fetchProductGroups())
      this.opts.dispatch(fetchCountries())
      this.opts.dispatch(fetchDeliveryWays())
    })

    route(tag => {
      if (requireLogin.contains(tag) && !this.opts.login.loggedIn) {
        route('application')
        return
      }

      this.update({
        currentPage: tag
      })
    })

    this.on('update', () => {
      if (this.opts.login.initialCheck && this.opts.login.loggedIn && this.opts.login.user !== null) {
        this.allowRoute = true
      } else if (this.opts.login.initialCheck && !this.opts.login.loggedIn) {
        this.allowRoute = true
      }

      if (this.opts.login.loggedIn && !this.opts.login.loading && this.opts.login.user === null) {
        this.opts.dispatch(fetchUser())
      }

      if (!this.loggedIn && this.opts.login.loggedIn && !this.opts.app.loadingSettings) {
        this.opts.dispatch(fetchSettings())
      }

      if (!this.opts.login.loggedIn && requireLogin.contains(this.currentPage)) {
        route('login')
      }

      this.loggedIn = this.opts.login.loggedIn;
    })

    const mapStateToOpts = (state) => {
      return {
        app: state.app,
        login: state.login
      }
    }

    this.reduxConnect(mapStateToOpts)
  </script>
</app>