<site-header>
  <accept-cookie />
  <header>
    <div class="loginMobile">
      <div class="login">
        <a class="extralink" href="#orders" if={opts.login.loggedIn}>Ordrar</a>
        <a class="extralink" href="#profile" if={opts.login.loggedIn}>Profil</a>
        <a href="#login" if={!opts.login.loggedIn}>Logga in</a>
        <a onclick={logout} if={opts.login.loggedIn}>Logga ut</a>
      </div>
    </div>
    <div class="logo">
      <a href="#">
        <img src="{IAMinterior}" alt="">
      </a>
    </div>
    <div class="loginContainer">
      <div class="loginDesktop">
        <div class="login">
          <a class="extralink" href="#orders" if={opts.login.loggedIn}>Orderhistorik</a>
          <a class="extralink" href="#profile" if={opts.login.loggedIn}>Profil</a>
          <a href="#login" if={!opts.login.loggedIn}>Logga in</a>
          <a onclick={logout} if={opts.login.loggedIn}>Logga ut</a>
        </div>
      </div>
      <div class="widgets" if={opts.login.loggedIn}>
        <search-widget />
        <shoppingcart-widget />
      </div>
    </div>
    </div>
  </header>
  <script>
    import './style.scss'
    import 'tags/features/shoppingcart/shoppingcart-widget'
    import 'tags/features/search-widget'
    import IAMinterior from 'images/siteimages/logo.png'
    import 'tags/features/accept-cookie'
    import {
      logout
    } from 'tags/features/login-feature/duck'

    this.IAMinterior = IAMinterior

    const mapStateToOpts = state => {
      return {
        login: state.login
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.logout = () => {
      this.opts.dispatch(logout())
    }
  </script>
</site-header>