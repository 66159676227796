import initialState from './initialState'
import {
  getCookie,
  setCookie
} from 'lib/utils' 

const CHECKED_COOKIE = 'iam/features/accept-cookie/CHECKED_COOKIE'
const ACCEPT_COOKIE = 'iam/features/accept-cookie/ACCEPT_COOKIE'

//Actions
export const checkedCookie = accepted => {
  accepted = accepted || false
  return {
    type: CHECKED_COOKIE,
    accepted
  }
}

export const setAccepted = () => {
  return {
    type: ACCEPT_COOKIE
  }
}

//Reducer
export default function reducer(state = initialState, action) {
  switch(action.type) {
    case CHECKED_COOKIE: 
      return {
        ...state,
        checked: true,
        accepted: action.accepted
      }
    case ACCEPT_COOKIE:
      return {
        ...state,
        accepted: true
      }
    default:
      return state
  }
}

//Commands
export const checkCookie = () => {
  const accepted = getCookie('acceptedCookies')
  const isAccepted = accepted !== '' && accepted !== undefined && accepted !== null && accepted !== false

  return dispatch => dispatch(checkedCookie(isAccepted))
}

export const acceptedCookie = () => {
  setCookie('acceptedCookies', true, 60)
  return dispatch => dispatch(setAccepted())
}