<shoppingcart-widget onmouseover={showDropdown} onmouseleave={hideDropdown} onclick={toggleDropdown}>
  <div class="container">
    <div class="cartIcon">
      <raw content={cart} />
    </div>
    <div class="arrow">
      <i ref="arrow" class="arrowDown"></i>
    </div>
  </div>
  <div class="dropTab {show: opts.shoppingcart.showWidget}">
    <div class="content">
      <loading-spinner if={opts.shoppingcart.loading} />
      <div if={opts.shoppingcart.data && !opts.shoppingcart.loading}>
        <div if={opts.shoppingcart.data.Rows && !opts.shoppingcart.data.Rows.length || !opts.shoppingcart.data.Rows}>Du har inga produkter i varukorgen.</div>
        <div if={opts.shoppingcart.data.Rows && opts.shoppingcart.data.Rows.length && !opts.orderComplete} class="cartRows">
          <div class="cartItem col-xs-12 row" each={row in opts.shoppingcart.data.Rows}>
            <div class="imgDiv col-xs-4 row">
              <img class="col-md-6" riot-src={apiUrl}{endpoint}/{row.ImageId} alt="">
              <div onclick={deleteArticle} class="col-md-3">x</div>
            </div>
            <div onclick={gotoArticle} class="text col-xs-8">{row.ArticleText} </div>
            <div class="price col-xs-12 end-xs">{row.Quantity} x {row.Price.format(2)}kr</div>
          </div>
        </div>
        <div class="summary">
          <div class="totalAmount row">
            <div class="col-xs">Delsumma:</div>
            <div class="col-xs end-xs">{opts.orderComplete ? '0,00' : opts.shoppingcart.data.TotalAmount.format(2)}kr</div>
          </div>
          <div class="buttons end-xs">
            <button onclick={gotoCart} type="button">Visa varukorgen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <script>
    import './style.scss'
    import 'tags/common/raw'
    import cart from 'images/svg/shopping-cart.svg'
    import route from 'riot-route'
    import {
      fetchCart,
      showWidget,
      hideWidget,
      toggleWidget,
      removeCartArticle
    } from '../duck'
    //import {
    //  changeStep
    //} from 'tags/features/shoppingcart/checkout-feature/duck'
    import {
      urlName,
      isMobile
    } from 'lib/utils'
    import 'tags/common/loading-spinner'
    this.cart = cart
    this.apiUrl = process.env.API_URL
    this.endpoint = 'api/articleimage/'

    const mapStateToOpts = state => {
      return {
        shoppingcart: state.shoppingcart,
        orderComplete: state.shoppingcart.orderComplete,
      }
    }
    this.reduxConnect(mapStateToOpts)

    this.gotoCart = () => {
      route('cart')
    }

    this.gotoCheckOut = () => {
      //this.opts.dispatch(changeStep(1))
      //route('checkout')
    }

    this.gotoArticle = (e) => {
      const articletext = urlName(`${e.item.row.ArticleText}`)

      if (e.item.row)
        route(`article/${articletext}/${e.item.row.ArticleId}`)
    }

    this.deleteArticle = (e) => {
      if (e.item.row.Id)
        this.opts.dispatch(removeCartArticle(e.item.row.Id))
    }

    this.showDropdown = () => {
      if (!this.opts.shoppingcart.showWidget && !isMobile())
        this.opts.dispatch(showWidget())
    }

    this.hideDropdown = () => {
      if (this.opts.shoppingcart.showWidget)
        this.opts.dispatch(hideWidget())
    }

    this.toggleDropdown = () => {
      this.opts.dispatch(toggleWidget())
    }

    this.on('mount', () => {
      this.opts.dispatch(fetchCart())
    })
  </script>
</shoppingcart-widget>