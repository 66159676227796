<mini-navigation>
  <div class="miniNavigation">
    <span onclick={miniNav} class="spanNav">Hem</span>
    <span if={categoryItem}>/</span>
    <span onclick={miniNav} if={categoryItem} class="spanNav">{categoryItem.Name}</span>
    <span if={articleText}>/</span>
    <span if={articleText} class="spanNavLast">{articleText}</span>
  </div>
  <script>
    import './style.scss'
    import route from 'riot-route'
    import {
      urlName
    } from 'lib/utils'
    this.categoryItem = null
    this.productGroupId = null
    this.articleText = opts.articletext || null

    const mapStateToOpts = state => {
      return {
        productCategory: state.products.groups.items
      }
    }

    this.reduxConnect(mapStateToOpts)


    this.miniNav = (e) => {
      if (e.target.textContent.includes('Hem'))
        route('/')
      else
        route('shop/' + urlName(this.categoryItem.Name) + '/' + this.categoryItem.ProductGroupId)
    }

    this.getCategory = () => {
      if (this.opts.productgroupid && this.opts.productCategory)
        if (this.opts.productgroupid !== this.productGroupId) {
          this.productGroupId = this.opts.productgroupid || null

          this.categoryItem = this.opts.productCategory.find(category => {
            return category.ProductGroupId === Number(this.opts.productgroupid)
          })
          this.update()
        }
    }

    this.on('update', () => {
      this.getCategory()
    })

    this.on('mount', () => {
      this.getCategory()
    })
  </script>
</mini-navigation>