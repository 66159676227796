<category-items>
  <h3 class="categoryHeader" if={category}>{category.Name}</h3>
  <h3 if={this.opts.search} class="categoryHeader">Sökresultat</h3>
  <h3 if={this.opts.highlights} class="categoryHeader">Bästsäljare</h3>
  <div if={!this.opts.search && !this.opts.highlights}>
    <mini-navigation productgroupid={opts.productGroupId} />
  </div>
  <loading-spinner if={opts.loading} />
  <div if={opts.result}>
    <div class="filterHeader row">
      <div class="viewChange col-sm-6 start-sm center-xs col-xs-12">
        <span class="start-xs">Visningsalternativ</span>
        <raw onclick={viewChange} class="{active: !viewMode}" content={gridView} />
        <raw onclick={viewChange} class="{active: viewMode}" content={listView} />
      </div>
      <div if={!this.opts.highlights} class="filter col-sm-6 end-sm col-xs-12 center-xs">
        <select ref="orderby" name="orderby" onchange={orderByChanged} value={opts.orderBy}>
          <option value="CreatedDesc">Sortera efter nyhet</option>
          <option value="Price">Sortera efter lägsta pris</option>
          <option value="PriceDesc">Sotera efter högsta pris</option>
        </select>
      </div>
    </div>
    <div class="row {center-xs: !viewMode}">
      <div if={viewMode} each={article in opts.result} class="articleContainer col-xs-12 col-sm-12 col-md-6">
        <div class="articleItems">
          <article-item article={article} viewmode={viewMode} estimateddelivery={opts.estimatedDeliveryDate} loggedin={opts.loggedIn}
            addtocart={addToCart} />
        </div>
      </div>
      <div if={!viewMode} each={article in opts.result} class="articleContainer">
        <div class="articleItems col-xs-12">
          <article-griditem article={article} viewmode={viewMode} />
        </div>
      </div>
    </div>
    <div if={!opts.loading && !opts.result} class="noarticles">
      Hittade inga artiklar utifrån valda kriterier...
    </div>
  </div>
  <script>
    import './style.scss'
    import 'tags/articlecards/article-item'
    import 'tags/articlecards/article-griditem'
    import 'tags/features/mini-navigation'
    import 'tags/common/pagination-view'
    import 'tags/common/raw'
    import 'tags/common/loading-spinner'
    import gridView from 'images/svg/grid-view.svg'
    import listView from 'images/svg/list-view.svg'
    import listViewAlternate from 'images/svg/list-view-alternate.svg'
    import {
      fetchArticles,
      searchArticles
    } from './../duck'
    import {
      addToCart
    } from 'tags/features/shoppingcart/duck'

    this.gridView = gridView
    this.listView = listView
    this.listViewAlternate = listViewAlternate
    this.viewMode = true
    this.category = null

    const mapStateToOpts = state => {
      return {
        result: state.products.articles.result,
        loading: state.products.articles.loading,
        productGroupId: state.products.articles.productGroupId,
        productGroups: state.products.groups,
        orderBy: state.products.articles.orderBy,
        estimatedDeliveryDate: state.app.settings.EstimatedDeliveryDate,
        search: state.products.articles.search,
        isSearched: state.products.articles.isSearched,
        searchCriteria: state.products.articles.searchCriteria,
        highlights: state.products.articles.highlights,
        loggedIn: state.login.loggedIn
      }
    }

    this.reduxConnect(mapStateToOpts)

    this.viewChange = () => {
      this.viewMode = !this.viewMode
    }

    this.orderByChanged = e => {
      const {
        value
      } = e.target

      if (this.opts.isSearched) {
        this.opts.dispatch(searchArticles(this.opts.searchCriteria, value))
      } else {
        this.opts.dispatch(fetchArticles(this.opts.productGroupId, value))
      }
    }

    this.getCategoryName = () => {
      if (this.opts.productGroups.items) {
        let categoryItem = this.opts.productGroups.items.find(category => {
          return category.ProductGroupId === this.opts.productGroupId
        })

        this.category = categoryItem
      }
    }

    this.addToCart = (articleId, quantity) => {
      this.opts.dispatch(addToCart(articleId, quantity))
    }

    this.on('update', () => {
      this.getCategoryName()
    })

    this.on('mount', () => {
      this.getCategoryName()
    })
  </script>
</category-items>