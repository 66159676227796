import StackTrace from 'stacktrace-js'

var handleError = function (error, msg) {
  if (!error) return

  // callback is called with an Array[StackFrame]
  StackTrace.fromError(error).then(function (stackframes) {
    if (window.location && window.location.hostname === 'localhost') {
      console.error(msg)
      //var stringifiedStack = stackframes.map(function (sf) {
      //  return sf.toString()
      //}).join('\n')
      //console.log(stringifiedStack)
    } else {
      var url = process.env.API_URL
      var params = {
        message: msg,
        url: window.location.href
      }
      StackTrace.report(stackframes, `${url}/api/reportjs`, params)
    }
  }).catch(function (err) {
    console.log('Unable to catch error')
    console.log(err)
  })
}

/*
 * Catch global errors
 */
window.onerror = function (msg, file, line, col, error) {
  handleError(error, msg)
}

/*
 * Catch errors in riot 
 * Disable this code to get default riot error handler
 */
riot.util.tmpl.errorHandler = function (err) {
  var tagName = '<' + err.riotData.tagName + '>' || 'Unknown tag'
  var message = err.message + ' in ' + tagName

  handleError(err, message)
}