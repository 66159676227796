import riot from 'riot'
import 'riot-hot-reload'
import 'tags/app'
import 'lib/mixins'
import 'lib/extentions'
import 'lib/polyfills'
import riotReduxConnect from 'riot-redux-connect'
import store from 'redux/store'
import 'lib/stacktracereport'
import 'whatwg-fetch'

riotReduxConnect(riot, store)

riot.mount('app')

if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!')
  console.log('API: ' + process.env.API_URL)
}