<shop-page>
  <div>
    <shop-feature shopname={action} groupid={productGroupId} />
  </div>
  <script>
    import './style.scss'
    import 'tags/features/products/shop-feature'
    this.action = null
    this.productGroupId = null

    this.on('route', (action, item) => {
      this.action = action
      this.productGroupId = item
    })
  </script>
</shop-page>