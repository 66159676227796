export default {
  name: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  address1: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  address2: {
    minLength: 2,
    maxLength: 40
  },
  zipcode: {
    required: true,
    minLength: 5
  },
  city: {
    required: true,
    minLength: 2,
    maxLength: 40
  },
  countrycode: {
    required: true,
    minLength: 2
  },
}