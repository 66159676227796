<article-griditem>
  <div class="gridViewDiv">
    <div class="gridImg shadowed" onclick={showArticle}>
      <img if={article.ImageId} riot-src={apiUrl}{endpoint}{article.ImageId} alt={article.ArticleText}>
      <img if={!article.ImageId} riot-src={noimg} alt={article.ArticleText}>
    </div>
    <div class="infoContainerGridView">
      <span class="subHeaderGridView">{article.ArticleTextShort}</span>
      <div>ArtikelNr: {article.ArticleNumber}</div>
      <div class="infoGridView">
        <span>({article.QuantityPerPackage} st/fp)</span>
      </div>
      <div class="spnBtnGridView">
        <a onclick={showArticle}>Välj alternativ</a>
      </div>
    </div>
  </div>
  <script>
    import './style.scss'
    import route from 'riot-route'
    import noimg from 'images/siteimages/noimage.jpg'
    import {
      urlName
    } from 'lib/utils'
    this.apiUrl = process.env.API_URL
    this.endpoint = opts.endpoint || 'api/articlethumbnail/'
    this.article = opts.article || {}
    this.noimg = noimg

    this.showArticle = () => {
      route('article/' + urlName(this.article.ArticleText) + '/' + this.article.Id)
    }
  </script>
</article-griditem>